'use client';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

export default function NotFound() {
	const router = useRouter();
	return (
		<div className="mx-auto mt-20 max-w-7xl px-5 font-serif lg:px-0">
			<p className="text-bold text-balance mb-5 text-center text-2xl md:text-3xl">
				Oops! We were unable to find that page.
			</p>
			<div>
				<p className="text-pretty text-center text-2xl md:text-3xl">
					We apologize for the inconvenience. Try going{' '}
					<span
						className="hover:text-UC-blue-500 font-semibold underline transition-all duration-300 ease-in-out hover:cursor-pointer"
						onClick={() => {
							router.back();
						}}
					>
						back a page
					</span>{' '}
					or to our{' '}
					<Link
						className="hover:text-UC-blue-500 text-2xl font-semibold text-black underline transition-all duration-300 ease-in-out md:text-3xl"
						href="/home"
						prefetch
					>
						homepage.
					</Link>
				</p>
			</div>
		</div>
	);
}
